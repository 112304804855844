import request from '@/utils/request'

// 访客管理--获取访客访问申请订单列表 
export function getVisitorOrderList(siteId,body) {
    return request({
        url: "bivale/visitor/order/"+siteId+"/list",
        method: 'post',
        data: body,
    });
}

// 访客管理--获取访客人员记录列表 
export function getVisitorPeopleList(body) {
    return request({
        url: "bivale/visitor/order/people/list",
        method: 'post',
        data: body,
    });
}


// 访客管理--获取访客信息列表 
export function getVisitorList(siteId,body) {
    return request({
        url: "bivale/visitor/guest/"+siteId+"/list",
        method: 'post',
        data: body,
    });
}

// 访客管理--添加访客信息
export function insertVisitorPersonInfo(siteId,body) {
    return request({
        url: "bivale/visitor/guest/"+siteId+"/save",
        method: 'post',
        data: body,
    });
}

// 访客管理--修改访客信息
export function updateVisitorPersonInfo(siteId,body) {
    return request({
        url: "bivale/visitor/guest/"+siteId+"/update",
        method: 'post',
        data: body,
    });
}

// 访客管理--删除访客信息
export function deleteVisitorPersonInfo(siteId,personInfoId) {
    return request({
        url: "bivale/visitor/guest/"+siteId+"/delete/"+personInfoId,
        method: 'post',
    });
}

// 访客管理--获取访客黑名单信息列表 
export function getVisitorBlacklistList(siteId,body) {
    return request({
        url: "bivale/visitor/blacklist/"+siteId+"/list",
        method: 'post',
        data: body,
    });
}

// 访客管理--添加访客黑名单信息
export function insertVisitorBlacklist(siteId,body) {
    return request({
        url: "bivale/visitor/blacklist/"+siteId+"/save",
        method: 'post',
        data: body,
    });
}

// 访客管理--修改访客黑名单信息
export function updateVisitorBlacklist(siteId,body) {
    return request({
        url: "bivale/visitor/blacklist/"+siteId+"/update",
        method: 'post',
        data: body,
    });
}

// 访客管理--删除访客黑名单信息
export function deleteVisitorBlacklist(siteId,personInfoId) {
    return request({
        url: "bivale/visitor/blacklist/"+siteId+"/delete/"+personInfoId,
        method: 'post',
    });
}

// 访客管理--获取访客访问规则信息列表 
export function getVisitRuleList(siteId) {
    return request({
        url: "bivale/visitor/rule/"+siteId+"/list",
        method: 'get',
    });
}

// 访客管理--更新访问规则参数设置信息
export function updateVisitRuleValue(siteId,body) {
    return request({
        url: "bivale/visitor/rule/"+siteId+"/update",
        method: 'post',
        data: body,
    });
}

// 访客管理--获取人员的上下级关系列表
export function getPersonSuperiorList(siteId,personId) {
    return request({
        url: "bivale/visitor/rank/"+siteId+"/superior/"+personId,
        method: 'get',
    });
}

// 访客管理--更新人员的上下级关系信息
export function updatePersonRankInfo(siteId,body) {
    return request({
        url: "bivale/visitor/rank/"+siteId+"/update",
        method: 'post',
        data: body,
    });
}

// 访客管理--获取已接访访客统计数据
export function getVisitorReportData(body) {
    return request({
        url: "bivale/vst/statistics/vstReport",
        method: 'post',
        data: body,
    });
}

// 访客管理--获取未来访客统计数据
export function getVisitorReportData2(body) {
    return request({
        url: "bivale/vst/statistics/vstReport2",
        method: 'post',
        data: body,
    });
}

// 访客管理--获取访客详细信息
export function getVisitorDetails(siteId,personId) {
    return request({
        url: "bivale/visitor/guest/"+siteId+"/info/"+personId,
        method: 'get',
    });
}

//获取访客访问申请订单详细信息
export function getVisitorOrderInfo(siteId,orderId) {
    return request({
        url: "bivale/visitor/order/"+siteId+"/info/"+orderId,
        method: 'get',
    });
}

// 访客管理--确认访客状态
export function confirmVisitorStatus(siteId,body) {
    return request({
        url: "bivale/visitor/order/"+siteId+"/people/status/confirm",
        method: 'post',
        data: body,
    });
}

// 访客管理--更新访客访问申请订单备注
export function updateMemo(siteId,params) {
    return request({
        url: "bivale/visitor/order/"+siteId+"/update/memo",
        method: 'post',
        data: params,
    });
}

//访客管理--获取访客参数字典信息列表
export function getVisitorDictionary(siteId) {
    return request({
        url: "bivale/visitor/dictionary/"+siteId+"/list",
        method: 'post',
    });
}

// 访客管理--清除滞留人员
export function clearStrandVisitors(params) {
    return request({
        url: "bivale/visitor/people/Strand/Clear",
        method: 'post',
        params,
    });
}

// 访客管理--取消订单
export function cancelVisitorOrders(params) {
    return request({
        url: "bivale/visitor/order/cancel",
        method: 'post',
        params,
    });
}


// 访客管理--推送访客信息
export function pushVisitor(params) {
    return request({
        url: "bivale/visitor/guest/push",
        method: 'post',
        data:params,
    });
}

// 访客管理--推送访客黑名单信息
export function pushVisitorBlacklist(params) {
    return request({
        url: "bivale/visitor/blacklist/push",
        method: 'post',
        data:params,
    });
}

// 访客管理--推送访客规则参数信息
export function pushVisitDictionary(params) {
    return request({
        url: "bivale/visitor/dictionary/push",
        method: 'post',
        data:params,
    });
}

export function getVisitorPassRecordList(params) {
    return request({
        url: "bivale/visitor/pass/record/list",
        method: 'post',
        data: params,
    });
}

// / 访客---黑名单---导出黑名单信息/下载模板文件
export function downloadVisitorBlackList(params) {
    return request({
        url: "bivale/visitor/blacklist/download",
        method: 'post',
        data: params
    });
}

// / 访客---黑名单管理---批量上传黑名单信息
export function uploadVisitorBlackData(params){
    return request({
        url: 'bivale/visitor/blacklist/upload',
        method: 'post',
        processData: false,
        data:params
    });
}