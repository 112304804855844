<!--门禁模块--访客统计报表-->
<template>
  <div id="visitorStatisticalReport" v-loading="loading" :element-loading-text="`${$t('visitorStatisticalReport.a1')}....`">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("visitorStatisticalReport.a2") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="[16,16]" type="flex" align="middle">
          <!-- 访客单位/公司 -->
          <a-col :span="3">
            <a-input v-model="form.visitorCompany" allow-clear :placeholder="$t('visitorStatisticalReport.a9')"></a-input>
          </a-col>
          <!-- 访客姓名 -->
          <a-col :span="3">
            <a-input v-model="form.visitorName" allow-clear :placeholder="$t('visitorStatisticalReport.a10')"></a-input>
          </a-col>
          <!-- 访客手机号码 -->
          <a-col :span="3">
            <a-input v-model="form.visitorPhone" allow-clear :placeholder="$t('visitorStatisticalReport.a21')"></a-input>
          </a-col>
          <!-- 访客类型 -->
          <a-col :span="3">
            <a-input v-model="form.visitorType" allow-clear :placeholder="$t('visitorStatisticalReport.a22')"></a-input>
          </a-col>
          <!-- 受访人所属 -->
          <a-col :span="3">
            <a-input v-model="form.personDept" allow-clear :placeholder="$t('visitorStatisticalReport.a23')"></a-input>
          </a-col>
          <!-- 受访人姓名 -->
          <a-col :span="3">
            <a-input v-model="form.personName" allow-clear :placeholder="$t('visitorStatisticalReport.a24')"></a-input>
          </a-col>
          <!-- 受访人代码 -->
          <a-col :span="3">
            <a-input v-model="form.personCode" allow-clear :placeholder="$t('visitorStatisticalReport.a25')"></a-input>
          </a-col>
          <!-- 受访人手机号码 -->
          <a-col :span="3">
            <a-input v-model="form.personPhone" allow-clear :placeholder="$t('visitorStatisticalReport.a26')"></a-input>
          </a-col>
          <!-- 显示对象 -->
          <a-col :span="3">
            <a-select v-model="condition1" style="width:100%">
              <a-select-option v-for="item in conditions1" :key="item.key">{{ item.title }}</a-select-option>
            </a-select>
          </a-col>
          <!-- 日期时间 -->
          <a-col :span="9">
            <a-input-group compact>
              <!-- 初始日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="form.date1" :disabled-date="disabledStartDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" style="width:100%"/>
              </div>
              <!-- 过渡线 -->
              <div style=" width: 10%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="form.date2" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width:100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 显示项目 -->
          <a-col :span="8">
            <a-checkbox-group :options="conditions2" v-model="form.condition2"></a-checkbox-group>
          </a-col>
          <!-- 功能按钮 -->
          <a-col :span="4">
            <a-button-group>
              <!-- 查询 -->
              <a-button type="primary" @click="inquire">{{ $t("visitorStatisticalReport.a8") }}</a-button>
              <!-- CSV导出 -->
              <a-button type="primary" icon="file-text" ghost :disabled="list.length == 0" @click="csvExport">{{ $t("alarm.a51") }}</a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="main" ref="main" v-table-size="tableSize">
      <a-table :columns="columns" :dataSource="list" :pagination="pagination" :scroll="size" :rowKey="(record) => record.key"></a-table>
    </div>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import { getVisitorReportData } from "../../api/visitor";
import { csvDownload } from "../../utils/utils";
import moment from "moment";

export default {
  name: "visitorStatisticalReport",
  data() {
    return {
      siteId: this.$route.query.id,
      condition1: "1",
      form: {
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
        condition2: [],
        visitorCompany: "",
        visitorName: "",
        visitorPhone: "",
        visitorType: "",
        personCode: "",
        personDept: "",
        personName: "",
        personPhone: "",
      },
      conditions1: [
        { key: "3", title: this.$t("visitorStatisticalReport.a27") },
        { key: "2", title: this.$t("visitorStatisticalReport.a28") },
        { key: "1", title: this.$t("visitorStatisticalReport.a29") },
      ],
      conditions2: [
        {
          value: "TENANT_ID",
          label: this.$t("visitorStatisticalReport.a30"),
          dataIndex: "tenantId",
        },
        {
          value: "VISITOR_NAME",
          label: this.$t("visitorStatisticalReport.a10"),
          dataIndex: "visitorName",
        },
        {
          value: "VISITOR_COMPANY",
          label: this.$t("visitorStatisticalReport.a9"),
          dataIndex: "visitorCompany",
        },
        {
          value: "PERSON_DEPT",
          label: this.$t("visitorStatisticalReport.a23"),
          dataIndex: "dept",
        },
        {
          value: "MANAGER_CODE",
          label: this.$t("visitorStatisticalReport.a25"),
          dataIndex: "managerCode",
        },
        {
          value: "PERSON_NAME",
          label: this.$t("visitorStatisticalReport.a24"),
          dataIndex: "personName",
        },
      ],
      loading: false,
      startDate: undefined,
      list: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 50, //每页中显示10条数据
        showTotal: (total) =>
          `${total} ${this.$t("visitorStatisticalReport.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a12"),
          dataIndex: "date",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a13"),
          dataIndex: "visitSum",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a14"),
          dataIndex: "notVisitSum",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a15"),
          dataIndex: "inVisitSum",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a16"),
          dataIndex: "outVisitSum",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a17"),
          dataIndex: "finVisitSum",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a18"),
          dataIndex: "stVisitSum",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a19"),
          dataIndex: "cnlVisitSum",
          ellipsis: true,
          align: "center",
        },
        {
          title: this.$t("visitorStatisticalReport.a20"),
          dataIndex: "misVisitSum",
          ellipsis: true,
          align: "center",
        },
      ],
      size: { x: 1200, y: 600 },
    };
  },
  // 监听显示对象（日度统计/月度统计/年度统计）选择值
  watch: {
    condition1: {
      handler(newValue) {
        switch (newValue) {
          case "3":
            this.form.date1 = moment().startOf("year");
            this.form.date2 = moment().endOf("day");
            break;
          case "2":
            this.form.date1 = moment().startOf("month");
            this.form.date2 = moment().endOf("day");
            break;
          case "1":
            this.form.date1 = moment().startOf("day");
            this.form.date2 = moment().endOf("day");
            break;
          default:
            break;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.tableSize();
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods: {
    // 表格CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD");
      const date2 = this.form.date2.format("YYYYMMDD");
      const name =this.$t("visitorStatisticalReport.a2");
      const fileName = `${date1}~${date2}${name}`;  
      //转换列表数据对象   
      csvDownload(this.list, this.columns, fileName);
    },
    moment,
    // 表格大小调节
    tableSize() {
      const indexDom = document.getElementById("visitorStatisticalReport")
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title = indexDom.getElementsByClassName("ant-table-thead")?indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight:0
        const tableHeight = height - 64 - title;
        this.size.y = tableHeight;
      }
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      if(value){ this.form.date2 = moment(value._d).endOf("day") }
    },
    // 条件查询方法
    inquire() {
      this.loading = true;
      // 日期时间空值修改
      const date1 = this.form.date1
      const date2 = this.form.date2
      const showValue = this.condition1
      if(!date1 || !date2){
        switch (showValue) {
          case "3":
            this.form.date1 = moment().startOf("year")
            this.form.date2 = moment().endOf("day")
            break;
          case "2":
            this.form.date1 = moment().startOf("month")
            this.form.date2 = moment().endOf("day")
            break;
          case "1":
            this.form.date1 = moment().startOf("day")
            this.form.date2 = moment().endOf("day")
            break;
          default:
            break;
        }
      }
      //改变显示列
      if (this.form.condition2.length > 0) {
        let addHeadName = "";
        this.columns.splice(10, this.columns.length - 10);
        for (let j = 0; j < this.form.condition2.length; j++) {
          addHeadName = this.form.condition2[j];
          for (let k = 0; k < this.conditions2.length; k++) {
            if (addHeadName === this.conditions2[k].value) {
              let column = {
                title: this.conditions2[k].label,
                dataIndex: this.conditions2[k].dataIndex,
                ellipsis: false,
              };
              this.columns.push(column);
            }
          }
        }
      }
      let par = {
        dateTimeBegin: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
        dateTimeEnd: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
        groupbyStrs: this.form.condition2,
        reportTypeStr: this.condition1,
        siteId: this.siteId,
        tenantId: 0,
        visitorCompany: this.form.visitorCompany,
        visitorName: this.form.visitorName,
        visitorPhone: this.form.visitorPhone,
        visitorType: this.form.visitorType,
        personCode: this.form.personCode,
        personDept: this.form.personDept,
        personName: this.form.personName,
        personPhone: this.form.personPhone,
      };
      console.log("getVisitorReportData par:", par);
      getVisitorReportData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          for (let i = 0; i < data.length; i++) {
            data[i].key = i;
          }
          this.list = data;
          console.log("getVisitorReportData res:", this.list);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 初始日期/时间的日期不可选范围
    disabledStartDate(startValue) {
      const endValue = this.date2;
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.date1;
      let startDate = this.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() >= endValue.valueOf() ||
        endValue >= moment().endOf() ||
        endValue >= moment(new Date(startDate)).add(1, "months")
      );
    },
  },
  components: {
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
#visitorStatisticalReport {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 171px;
  padding: 0 20px;
}
.query{
  margin: 15px 0px;
}
.main {
  height: calc(100% - 171px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
}
</style>